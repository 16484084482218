import axios from "axios";

export const verifyToken = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
        window.location.href = '/admin';
    } else {
        const tokenVerifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/login/verify`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }
        )
        if (!tokenVerifyResponse.ok) {
            await refreshToken();
        }
    }

}

export async function refreshToken() {
    const token = sessionStorage.getItem('refresh');
    if (!token) {
        window.location.href = '/admin';
    } else {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/login/refresh`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            });
            sessionStorage.setItem('token', response.data.token.access_token);
            sessionStorage.setItem('refresh', response.data.token.refresh_token);
        } catch (error) {
            console.error('Error:', error);
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('refresh');
            window.location.href = '/admin';
        }
    }
}